import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import NumberEasing from 'react-number-easing'
import {useInView} from 'react-intersection-observer'
import ReactTooltip from 'react-tooltip'

import {MainLayout} from 'layouts'
import {useAuth} from 'hooks'
import {LinkButton, SEO} from 'components'
import BackgroundSVG from 'images/background-small.svg'

const BasicSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const BasicContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: var(--content-width);
  }
`
const HeroSection = styled(BasicSection)`
  padding-bottom: 50px;
  background-color: var(--white);

  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0% 100%);
`

const HeroContent = styled(BasicContent)`
  text-align: center;

  font-weight: 600;
  font-size: 1.1em;

  h1 {
    margin-bottom: 0;
    font-size: 3em;
    color: var(--blue);
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);

    h1 {
      font-size: 5em;
    }
  }
`

const MetricsSection = styled(BasicSection)`
  padding-bottom: 50px;
`

const MetricsContent = styled(BasicContent)`
  align-items: flex-start;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const MetricsBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  width: 100%;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--white);
  filter: var(--shadow);

  h6 {
    margin: 0;
    color: var(--dark-grey);
  }

  p {
  }
`

const GoalNumberStyle = styled.strong`
  font-weight: 800;
  font-size: 4rem;

  @media only screen and (min-width: 700px) {
    font-weight: 800;
    font-size: 3.5rem;
  }
`
const GoalNumber = ({number, dollar = false}) => {
  const {ref, inView} = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  return (
    <GoalNumberStyle ref={ref}>
      {dollar && `$`}
      <NumberEasing
        value={inView ? number : 0}
        speed={1000}
        ease='cubicInOut'
        customFunctionRender={(currentValue) => Math.floor(Number(currentValue)).toLocaleString('en')}
      />
    </GoalNumberStyle>
  )
}

const CallToActionSection = styled(BasicSection)`
  padding: 50px 0;
  background: center / cover no-repeat url(${BackgroundSVG});
  background-color: var(--white);
`

const CallToActionContent = styled(BasicContent)`
  text-align: center;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const HintText = styled.p`
  color: var(--dark-grey);
  font-size: 0.9em;
`

const OpenStartupPage = ({data}) => {
  const {user} = useAuth()
  console.log(data)
  const {numCreators, numLearners, numProjectsStarted, arr, mrr} = data.analytics
  const {uniquePageViews} = data.strapiStatistics

  return (
    <MainLayout>
      <SEO title='Metrics' description='The Codex is an open startup with public metrics!' />
      <HeroSection>
        <HeroContent>
          <h1>We{`'`}re Open.</h1>
          <p>The Codex is an open startup with public metrics!</p>
        </HeroContent>
      </HeroSection>
      <MetricsSection>
        <MetricsContent>
          <h2>Our Metrics</h2>
          <MetricsBoxes>
            <Metric data-tip='The number of active users on our platform.'>
              <h6>Number of learners</h6> <GoalNumber number={numLearners} />
            </Metric>
            <Metric data-tip='The number of active creator subscriptions.'>
              <h6>Number of Creators</h6> <GoalNumber number={numCreators} />
            </Metric>
            <Metric data-tip="The number of projects added to users' dashboards.">
              <h6>Number of projects started</h6> <GoalNumber number={numProjectsStarted} />
            </Metric>
            <Metric>
              <h6>Unique page views (30 days)</h6> <GoalNumber number={uniquePageViews} />
            </Metric>
            <Metric>
              <h6>Monthly Recurring Revenue</h6> <GoalNumber number={mrr / 100} dollar />
            </Metric>
            <Metric>
              <h6>Annual Recurring Revenue</h6> <GoalNumber number={arr / 100} dollar />
            </Metric>
            <ReactTooltip place='top' type='dark' effect='solid' delayShow={200} />
          </MetricsBoxes>
        </MetricsContent>
      </MetricsSection>
      {user === null && (
        <CallToActionSection>
          <CallToActionContent>
            <h2>Join our journey!</h2>
            <LinkButton to='/app/get-started' tier='primary'>
              Aye, aye, captain.
            </LinkButton>
            <HintText>ha, get it? cause... ships.</HintText>
          </CallToActionContent>
        </CallToActionSection>
      )}
    </MainLayout>
  )
}

export default OpenStartupPage

export const OpenStartupData = graphql`
  query OpenStartupQuery {
    analytics {
      arr
      mrr
      mrrIntervals {
        date
        mrr
      }
      numCreators
      numLearners
      numProjectsStarted
    }
    strapiStatistics {
      uniquePageViews
    }
  }
`
